import React, { useState } from 'react';
import "./UMAHospital.css";
import ImagePaths from '../../../Utility/ImagePaths';
import { post } from '../../../Utility/RestAPIService';
import { ToastMsg } from '../../CommonFunctions/CommonFuntions';

function UMAHospital() {
    const [birthdate, setBirthdate] = useState('');
    const [age, setAge] = useState('');
    const [name, setName] = useState('');
    const [gender, setGender] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [pincode, setPincode] = useState('');
    const [email, setEmail] = useState('');
    const [isNotEmailValid, setIsNotEmailValid] = useState(false);
    const [refSource, setrefSource] = useState('');
    const [otherReferal, setotherReferal] = useState('');
    const [showOthersTextarea, setShowOthersTextarea] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [isChecked, setisChecked] = useState(false);
    const [isDisable, setisDisable] = useState(false);
    const [errors, setErrors] = useState({});
    const [isErrorMsg, setIsErrorMsg] = useState(false);
    const [successMsg, setsuccessMsg] = useState(false);
    const [isErrorMsgText, setIsErrorMsgText] = useState('');
    const [successMsgText, setsuccessMsgText] = useState('');

    const today = new Date().toISOString().split('T')[0];

    const calculateAgeFromBirthdate = (birthdate) => {
        const birthdateDate = new Date(birthdate);
        const today = new Date();
        let calculatedAge = today.getFullYear() - birthdateDate.getFullYear();
        const monthDifference = today.getMonth() - birthdateDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthdateDate.getDate())) {
            calculatedAge--;
        }
        if (calculatedAge <= 150 && calculatedAge >= 0) {
            setAge(calculatedAge);
        }
    };

    const calculateBirthdateFromAge = (inputAge) => {
        const today = new Date();
        today.setFullYear(today.getFullYear() - inputAge);

        setBirthdate(today.toISOString().split('T')[0]);
    };

    const handleDateChange = (event) => {
        const selectedDate = event.target.value;
        setBirthdate(selectedDate);
        calculateAgeFromBirthdate(selectedDate);
        setErrors((prevErrors) => ({ ...prevErrors, birthdate: '', age: '' }));
    };

    const handleAgeChange = (event) => {
        const inputAge = parseInt(event.target.value);
        if ((inputAge >= 0 && inputAge <= 150) || event.target.value === "") {
            setAge(inputAge);
            if (event.target.value !== "") {
                calculateBirthdateFromAge(inputAge);
            }
        }
        setErrors((prevErrors) => ({ ...prevErrors, age: '', birthdate: '' }));
    };

    const handleAddressChange = (event) => {
        const inputName = event.target.value;
        if (inputName.length <= 100) {
            setAddress(inputName);
        }
    }

    const handleOtherReferal = (event) => {
        const inputName = event.target.value;
        if (inputName.length <= 30) {
            setotherReferal(inputName);
        }
    }

    const handleNameChange = (event) => {
        const inputName = event.target.value;
        if (/^[a-zA-Z\s]*$/.test(inputName)) {
            if (inputName.length <= 50) {
                setName(inputName);
                setErrors((prevErrors) => ({ ...prevErrors, name: '' }));
            }
        }
    };

    const handlePhoneChange = (event) => {
        const inputPhone = event.target.value;
        if (/^\d{0,10}$/.test(inputPhone)) {
            setPhone(inputPhone);
            setErrors((prevErrors) => ({ ...prevErrors, phone: '' }));
        }
    };

    const handlePincodeChange = (event) => {
        const inputPincode = event.target.value;
        if (/^\d{0,6}$/.test(inputPincode)) {
            setPincode(inputPincode);
            setErrors((prevErrors) => ({ ...prevErrors, pincode: '' }));
        }
    };

    const handleGenderChange = (event) => {
        setGender(event.target.value);
        setErrors((prevErrors) => ({ ...prevErrors, Gender: '' }));
    };

    const handleSourceChange = (event) => {
        setShowOthersTextarea(false);
        if (event.target.value === refSource) {
            setrefSource("")
        } else {
            setrefSource(event.target.value);
        }
    };

    const validateEmail = (email) => {
        const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return emailPattern.test(email);
    };

    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        if(inputEmail?.length < 50) {
            setEmail(inputEmail);
        }
        // setIsNotEmailValid(validateEmail(inputEmail));
        setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
    };

    const handleOthersCheckboxChange = () => {
        setShowOthersTextarea(!showOthersTextarea);
        setrefSource('')
    };

    const toggleTermsVisibility = () => {
        setShowTerms(!showTerms);
    };

    const handleAgreeTermsChange = (event) => {
        setAgreeTerms(event.target.checked);
        setisChecked(event.target.checked)
        setErrors((prevErrors) => ({ ...prevErrors, agreeTerms: '' }));
    };

    const postPatientDetail = () => {
        let REGISTRATION_FORM_POST = 'fo/qr-appointment/registration/'
        try {
            let data = {
                "clinic_id": 204,
                "patient_name": name ? name : "",
                "gender": gender ? gender : "",
                "dob": birthdate ? birthdate : null,
                "age": age ? age : null,
                "address": address ? address : "",
                "pincode": pincode ? pincode : "",
                "mobile_number": phone ? phone : "",
                "email": email ? email : null,
                "referral_source": showOthersTextarea ? otherReferal : refSource
            }
            post(REGISTRATION_FORM_POST, data)
                .then((response) => {
                    if (response.data.status === 'success') {
                        successMessage(response.data.message)
                        setBirthdate("")
                        setAge("")
                        setName("")
                        setGender("")
                        setPhone("")
                        setAddress("")
                        setPincode("")
                        setEmail("")
                        setrefSource("")
                        setShowOthersTextarea(false)
                        setShowTerms(false)
                        setAgreeTerms('')
                        setotherReferal('')
                        setisChecked(false)
                        setisDisable(false)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        errorMessage(error.response.data.message)
                        setisDisable(false)
                    } else {
                        errorMessage(error.message)
                        setisDisable(false)
                    }
                })
        } catch (error) {
            errorMessage(error.message)
            setisDisable(false)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = {};
        setisDisable(true)

        if (!name) newErrors.name = 'Name is required';
        if (!birthdate) newErrors.birthdate = 'Birthdate is required';
        if (!age) newErrors.age = 'Age is required';
        if (!phone) newErrors.phone = 'Phone number is required';
        if (!agreeTerms) newErrors.agreeTerms = 'Please agree to the terms and conditions';
        if (!gender) newErrors.Gender = 'Please Select Gender';
        if (isNotEmailValid && email !== "") newErrors.email = 'Invalid E-mail';
        if (pincode.length !== 6 && pincode !== "") newErrors.pincode = 'Invalid Pincode';
        if (phone.length !== 10 && phone !== "") newErrors.phone = 'Invalid Mobile Number';
        if (showOthersTextarea && otherReferal === "") newErrors.referal = "Enter Other Referral Field"

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            postPatientDetail()
        } else {
            setisDisable(false)
        }
    };

    const errorMessage = (message) => {
        setIsErrorMsg(true)
        setIsErrorMsgText(message)
    }

    const successMessage = (message) => {
        setsuccessMsg(true)
        setsuccessMsgText(message)
    }

    const msgClose = () => {
        setIsErrorMsg(false)
        setIsErrorMsgText('')
        setsuccessMsg(false)
        setsuccessMsgText('')
    }

    return (
        <div className="formpage container" data-testid="eMed_formpage">
            <div className="header" data-testid="eMed_header">
                <img src={ImagePaths.UMAHeaderImage} alt="Header" data-testid="eMed_headerImage" />
            </div>
            <form onSubmit={handleSubmit} className="formpagedetails" data-testid="eMed_form">
                <div className="name" data-testid="eMed_name">
                    <div className="Heading">
                        <label className="label" data-testid="eMed_nameLabel">Enter your Name *</label>
                        {errors.name ? <p className="error" data-testid="eMed_nameError">{errors.name}</p> : null}
                    </div>
                    <div className="Input-Container">
                        <input type="text" value={name} onChange={handleNameChange} data-testid="eMed_nameInput" />
                    </div>
                </div>

                <div className="Gender" data-testid="eMed_gender">
                    <div className="Heading">
                        <label className="label" data-testid="eMed_genderLabel">Gender *</label>
                        {errors.Gender ? <p className="error" data-testid="eMed_genderError">{errors.Gender}</p> : null}
                    </div>
                    <div className="optionsofsex" data-testid="eMed_genderOptions">
                        <label style={{ marginLeft: "-0.2vw" }}>
                            <input type="radio" name="yoursex" value="m" checked={gender === 'm'} onChange={handleGenderChange} data-testid="eMed_genderMale" />
                            <div className="label" style={{ marginLeft: "0.25vw" }}>Male</div>
                        </label>
                        <label>
                            <input type="radio" name="yoursex" value="f" checked={gender === 'f'} onChange={handleGenderChange} data-testid="eMed_genderFemale" />
                            <div className="label" style={{ marginLeft: "0.25vw" }}>Female</div>
                        </label>
                        <label>
                            <input type="radio" name="yoursex" value="t" checked={gender === 't'} onChange={handleGenderChange} data-testid="eMed_genderOther" />
                            <div className="label" style={{ marginLeft: "0.25vw" }}>Other</div>
                        </label>
                    </div>
                </div>

                <div className="dob" data-testid="eMed_dob">
                    <div className="Heading">
                        <label className="label" data-testid="eMed_dobLabel">DOB *</label><br />
                        {errors.birthdate ? <p className="error" data-testid="eMed_dobError">{errors.birthdate}</p> : null}
                    </div>
                    <div className="Input-Container">
                        <input type="date" max={today} value={birthdate} onChange={handleDateChange} data-testid="eMed_dobInput" />
                    </div>
                </div>

                <div className="age" data-testid="eMed_age">
                    <div className="Heading">
                        <label className="label" data-testid="eMed_ageLabel">Age *</label><br />
                        {errors.age && <p className="error" data-testid="eMed_ageError">{errors.age}</p>}
                    </div>
                    <div className="Input-Container">
                        <input type="number" value={age} onChange={handleAgeChange} data-testid="eMed_ageInput" />
                    </div>
                </div>

                <div className="address" data-testid="eMed_address">
                    <div className="Heading">
                        <label className="label" data-testid="eMed_addressLabel">Address <br /></label>
                    </div>
                    <div className="Input-Container">
                        <textarea value={address} onChange={handleAddressChange} data-testid="eMed_addressInput"></textarea>
                    </div>
                </div>

                <div className="pincode" data-testid="eMed_pincode">
                    <div className="Heading">
                        <label className="label" data-testid="eMed_pincodeLabel">Pincode </label><br />
                        {errors.pincode && <p className="error" data-testid="eMed_pincodeError">{errors.pincode}</p>}
                    </div>
                    <div className="Input-Container">
                        <input type="text" value={pincode} onChange={handlePincodeChange} data-testid="eMed_pincodeInput" />
                    </div>
                </div>

                <div className="phonenum" data-testid="eMed_phonenum">
                    <div className="Heading">
                        <label className="label" data-testid="eMed_phoneLabel">Phone Number *</label><br />
                        {errors.phone && <p className="error" data-testid="eMed_phoneError">{errors.phone}</p>}
                    </div>
                    <div className="countrycodediv">
                        <span className="country-code">+91</span>
                        <input type="text" value={phone} onChange={handlePhoneChange} data-testid="eMed_phoneInput" />
                    </div>
                </div>

                <div className="emailid" data-testid="eMed_emailid">
                    <div className="Heading">
                        <label className="label" data-testid="eMed_emailLabel">Email ID</label><br />
                        {isNotEmailValid && email && <p className="error" data-testid="eMed_emailError">Please enter a valid email address.</p>}
                    </div>
                    <div className="Input-Container">
                        <input type="email" value={email} onChange={handleEmailChange} className={!isNotEmailValid ? 'valid' : 'invalid'} data-testid="eMed_emailInput" />
                    </div>
                </div>

                <div className="howdidu" data-testid="eMed_howdidu">
                    <p className="howdidupara" data-testid="eMed_howdiduPara">How did you hear about our hospital?</p>
                    <div className="checkboxlist">
                        <div className="source">
                            <input type="radio" id="s10health" value="S10Health" checked={refSource === 'S10Health'} onChange={handleSourceChange} onClick={handleSourceChange} data-testid="eMed_howdiduS10Health" />
                            <label className="label" htmlFor="s10health"> S10health</label>
                        </div>
                        <div className="source">
                            <input type="radio" id="newspaper" value="Newspaper" checked={refSource === 'Newspaper'} onChange={handleSourceChange} onClick={handleSourceChange} data-testid="eMed_howdiduNewspaper" />
                            <label className="label" htmlFor="newspaper"> Newspaper</label>
                        </div>
                        <div className="source">
                            <input type="radio" id="eyecamp" value="Eyecamp" checked={refSource === 'Eyecamp'} onChange={handleSourceChange} onClick={handleSourceChange} data-testid="eMed_howdiduEyeCamp" />
                            <label className="label" htmlFor="eyecamp"> Eye Camp</label>
                        </div>
                        <div className="source">
                            <input type="radio" id="website" value="Website" checked={refSource === 'Website'} onChange={handleSourceChange} onClick={handleSourceChange} data-testid="eMed_howdiduWebsite" />
                            <label className="label" htmlFor="website"> Website</label>
                        </div>
                        <div className="source">
                            <input type="radio" id="friends" value="Friends" checked={refSource === 'Friends'} onChange={handleSourceChange} onClick={handleSourceChange} data-testid="eMed_howdiduFriends" />
                            <label className="label" htmlFor="friends"> Friends / Relatives</label>
                        </div>
                    </div>
                    <div className="source checkboxlast">
                        <input type="radio" id="others" value="others" checked={showOthersTextarea === true} onChange={handleOthersCheckboxChange} onClick={handleOthersCheckboxChange} data-testid="eMed_howdiduOthers" />
                        <label className="label" htmlFor="others"> Others</label>
                        {showOthersTextarea && <textarea value={otherReferal} onChange={handleOtherReferal} data-testid="eMed_howdiduOthersInput"></textarea>}
                    </div>
                    {errors.referal && otherReferal === "" ? <p className="error" data-testid="eMed_referalError">{errors.referal}</p> : null}
                </div>

                <div className="termsandcondition" data-testid="eMed_termsandcondition">
                    <span className="tandcall">
                        <input type="checkbox" id="termsandcondition" checked={isChecked} name="termsandcondition" value="howdidyou" onChange={handleAgreeTermsChange} data-testid="eMed_termsCheckbox" />
                        <p className="termsandconditionshow" onClick={toggleTermsVisibility} data-testid="eMed_termsToggle"> Terms and Conditions {showTerms ? '▲' : '▼'}</p>
                    </span>
                    {showTerms && (
                        <p className="termspara label" data-testid="eMed_termsText">
                            I hereby state that the information provided above is correct. I acknowledge receipt of a copy of the patient's rights and responsibilities, which I have read.

                            I give my consent for an eye examination at Uma Eye Clinic. I have been informed about the highest safety standards followed by Uma Eye Clinic. However, I absolve the hospital management of any liability arising from unforeseen circumstances beyond the control of the hospital.

                            I further consent to the release of information from my medical records, if necessary, to relevant authorities in accordance with the rules and policies of the hospital.
                        </p>
                    )}
                    {errors.agreeTerms && <p className="erroragree" data-testid="eMed_termsError">{errors.agreeTerms}</p>}
                </div>

                <div className="submitbtn">
                    <input disabled={isDisable} type="submit" value="Submit" data-testid="eMed_submitButton" />
                </div>
            </form>
            <div className="Footer" data-testid="eMed_header">
                <img src={ImagePaths.UMAFooterImage} alt="Header" data-testid="eMed_headerImage" />
            </div>
            <div className="imagesall" data-testid="eMed_imagesall">
                <div className="emedlogo">
                    <span className="alreadypat"><p data-testid="eMed_emedLogoText">Download EmedHub </p> To view your reports and bills</span>
                </div>
                <div className="appstoreandplaystore" data-testid="eMed_appstoreAndPlaystore">
                    <a href="https://apps.apple.com/us/app/emedhub/id6475773185?platform=iphone*" target="_blank" rel="nofollow" data-testid="eMed_appstoreLink"> <img src={ImagePaths.AppStore} alt="Header" data-testid="eMed_appstoreImage" /></a>
                    <a href="https://play.google.com/store/apps/details?id=com.emedhub.clinic&pcampaignid=web_share" target="_blank" rel="nofollow" className="playstore" data-testid="eMed_playstoreLink"> <img src={ImagePaths.PlayStore} alt="Header" data-testid="eMed_playstoreImage" /></a>
                </div>
            </div>
            {isErrorMsg ?
                <ToastMsg
                    severity={'error'}
                    msg={isErrorMsgText}
                    msgPop={msgClose}
                />
                : null}
            {successMsg ?
                <ToastMsg
                    severity={'success'}
                    msg={successMsgText}
                    msgPop={msgClose}
                />
                : null}
        </div>
    );
}

export default UMAHospital;