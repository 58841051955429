import React, { useState } from 'react';
import './ShivaniMedicalCenter.css';
import ImagePaths from '../../../Utility/ImagePaths';
import { TextField, InputAdornment } from '@mui/material';
import { post } from '../../../Utility/RestAPIService';
import { ToastMsg } from '../../CommonFunctions/CommonFuntions';

const ShivaniMedicalCenter = () => {
  const [formData, setFormData] = useState({
    name: '',
    gender: '',
    birthdate: '',
    age: '',
    maritalStatus: '',
    fatherName: '',
    guardianName: '',
    address: '',
    pincode: '',
    phone: '',
    email: '',
    occupation: '',
    aadharNo: '',
    isDisable: false,
    errors: {},
    isErrorMsg: false,
    successMsg: false,
    isErrorMsgText: '',
    successMsgText: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const alpha = /^[a-zA-Z\s]*$/;
    const phoneNo = /^\d{0,10}$/;
    const validAadhar = /^\d{0,12}$/;
    const validPincode = /^\d{0,6}$/;

    switch (name) {
      case 'name':
      case 'fatherName':
      case 'guardianName':
        if ((alpha.test(value) && value.length <= 32) || value === "") {
          setFormData((prevData) => ({
            ...prevData,
            [name]: value,
            errors: {
              ...prevData.errors,
              [name]: ''
            }
          }));
        }
        break;
      case 'pincode':
        if ((validPincode.test(value) && value) || value === "") {
          setFormData((prevData) => ({
            ...prevData,
            pincode: value,
            errors: {
              ...prevData.errors,
              pincode: ''
            }
          }));
        }
        break;
      case 'birthdate':
        setFormData((prevData) => ({
          ...prevData,
          birthdate: value,
          errors: {
            ...prevData.errors,
            age: '',
            birthdate: ''
          }
        }));
        calculateAgeFromBirthdate(value);
        break;
      case 'age':
        if ((value >= 0 && value <= 150) || value === "") {
          setFormData((prevData) => ({
            ...prevData,
            age: value,
            errors: {
              ...prevData.errors,
              age: '',
              birthdate: ''
            }
          }));
          if (value !== "") {
            calculateBirthdateFromAge(value);
          }
        }
        break;
      case 'email':
        if ((value?.length < 50 && value) || value === "") {
          setFormData((prevData) => ({
            ...prevData,
            email: value,
            errors: {
              ...prevData.errors,
              email: ''
            }
          }));
        }
        break;
      case 'phone':
        if ((phoneNo.test(value) && value) || value === "") {
          setFormData((prevData) => ({
            ...prevData,
            phone: value,
            errors: {
              ...prevData.errors,
              phone: ''
            }
          }));
        }
        break;
      case 'aadharNo':
        if ((validAadhar.test(value) && value) || value === "") {
          setFormData((prevData) => ({
            ...prevData,
            aadharNo: value,
            errors: {
              ...prevData.errors,
              aadharNo: ''
            }
          }));
        }
        break;
      case 'address':
        if ((value?.length <= 100 && value) || value === "") {
          setFormData((prevData) => ({
            ...prevData,
            address: value,
            errors: {
              ...prevData.errors,
              address: ''
            }
          }));
        }
        break;
      case 'occupation':
        if ((value?.length <= 50 && value) || value === "") {
          setFormData((prevData) => ({
            ...prevData,
            occupation: value,
            errors: {
              ...prevData.errors,
              occupation: ''
            }
          }));
        }
        break;
      default:
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
          errors: {
            ...prevData.errors,
            [name]: ''
          }
        }));
        break;
    }

  };

  const today = new Date().toISOString().split('T')[0];

  const calculateAgeFromBirthdate = (birthdate) => {
    const birthdateDate = new Date(birthdate);
    const today = new Date();
    let calculatedAge = today.getFullYear() - birthdateDate.getFullYear();
    const monthDifference = today.getMonth() - birthdateDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthdateDate.getDate())) {
      calculatedAge--;
    }
    if (calculatedAge <= 150 && calculatedAge >= 0) {
      setFormData((prevData) => ({
        ...prevData,
        age: calculatedAge,
      }));
    }
  };

  const calculateBirthdateFromAge = (inputAge) => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - inputAge);
    setFormData((prevData) => ({
      ...prevData,
      birthdate: today.toISOString().split('T')[0],
    }));
  };

  const errorMessage = (message) => {
    setFormData((prevData) => ({
      ...prevData,
      isErrorMsg: true,
      isErrorMsgText: message,
    }));
  }

  const successMessage = (message) => {
    setFormData((prevData) => ({
      ...prevData,
      successMsg: true,
      successMsgText: message
    }));
  }

  const msgClose = () => {
    setFormData((prevData) => ({
      ...prevData,
      isErrorMsg: false,
      successMsg: false,
      isErrorMsgText: '',
      successMsgText: ''
    }));
  }

  const handleDisable = (flag) => {
    setFormData((prevData) => ({
      ...prevData,
      isDisable: flag,
    }));
  }

  const postPatientDetail = () => {
    let REGISTRATION_FORM_POST = 'fo/qr-appointment/registration/'
    try {
      let data = {
        'organization_id': 706,
        // 'organization_id': 686,
        'branch_id': 1,
        "patient_name": formData.name ? formData.name : "",
        "gender": formData.gender ? formData.gender : "",
        "dob": formData.birthdate ? formData.birthdate : null,
        "age": formData.age ? formData.age : null,
        "address": formData.address ? formData.address : "",
        "pincode": formData.pincode ? formData.pincode : "",
        "mobile_number": formData.phone ? formData.phone : "",
        "email": formData.email ? formData.email : null,
        'father_name': formData.fatherName ? formData.fatherName : "",
        'marital_status': formData.maritalStatus ? formData.maritalStatus : "",
        'guardian_name': formData.guardianName ? formData.guardianName : "",
        'aadhar_number': formData.aadharNo ? +formData.aadharNo : null,
        'occupation': formData.occupation ? formData.occupation : "",
        "referral_source": ""
      }
      post(REGISTRATION_FORM_POST, data)
        .then((response) => {
          if (response.data.status === 'success') {
            successMessage(response.data.message)
            setFormData((prevData) => ({
              ...prevData,
              name: '',
              gender: '',
              birthdate: '',
              age: '',
              maritalStatus: '',
              fatherName: '',
              guardianName: '',
              address: '',
              pincode: '',
              phone: '',
              email: '',
              occupation: '',
              aadharNo: '',
              isDisable: false,
              errors: {},
            }));
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            errorMessage(error.response.data.message)
            handleDisable(false)
          } else {
            errorMessage(error.message)
            handleDisable(false)
          }
        })
    } catch (error) {
      errorMessage(error.message)
      handleDisable(false)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = {};
    handleDisable(true);
    const validateRequiredField = (field, message) => {
      if (!formData[field]) newErrors[field] = message;
    };

    // Validate required fields
    validateRequiredField("name", 'Name is required');
    validateRequiredField("gender", 'Please select Gender');
    validateRequiredField("birthdate", 'Birthdate is required');
    validateRequiredField("phone", 'Phone number is required');

    if (!formData.birthdate && !formData.age) {
      newErrors.birthdate = 'Birthdate is required';
    }

    if (formData.phone && !/^\d{10}$/.test(formData.phone?.trim())) {
      newErrors.phone = 'Please enter a valid Phone Number.';
    }
    if (formData.email && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
      newErrors.email = 'Please enter a valid E-mail';
    }

    if (formData.aadharNo && !/^\d{12}$/.test(formData.aadharNo)) {
      newErrors.aadharNo = 'Please enter a valid Aadhar number';
    }

    if (formData.pincode && !/^\d{6}$/.test(formData.pincode)) {
      newErrors.pincode = 'Please enter a valid Pincode';
    }

    setFormData((prevData) => ({
      ...prevData,
      errors: newErrors,
    }));

    if (Object.keys(newErrors).length === 0) {
      postPatientDetail()
    } else {
      handleDisable(false);
    }
  };

  const { errors, isErrorMsg, isErrorMsgText, successMsg, successMsgText } = formData;
  return (
    <div className="formpage container overAllcontainer" data-testid="eMed_formpage">
      <div className="header" data-testid="eMed_header">
        <img src={ImagePaths.ShivaniHeaderImage} alt="Header" data-testid="eMed_headerImage" />
      </div>
      <h3 className="shiv_title" style={{ marginTop: "5px" }}>Out Patient Registration Information Form</h3>
      <h3 className="shiv_title">வெளி நோயாளி பதிவு தகவல் படிவம்</h3>
      <form onSubmit={handleSubmit} className="formPageDetails" data-testid="eMed_form">
        <div className='shiv_row' style={{ marginTop: "5px" }}>
          <div className='shiv_rowSub' >
            <label className="shiv_labels" data-testid="eMed_nameLabel">Patient Name / நோயாளியின் பெயர் *</label>
            <TextField
              value={formData.name}
              name="name"
              inputProps={{ maxLength: 32 }}
              autoComplete='off'
              onChange={handleInputChange}
              className='textField'
              size='small'
            />
            {errors.name ? <p className="error" data-testid="eMed_nameError">{errors.name}</p> : null}
          </div>
          <div className="Gender" data-testid="eMed_gender" style={{ width: "100%", marginTop: "5px" }}>
            <div className="GenderHeading">
              <label className="shiv_labels" data-testid="eMed_genderLabel">Gender / பாலினம் *</label>
              {errors.gender ? <p className="error" style={{ marginTop: "2px" }} data-testid="eMed_genderError">{errors.gender}</p> : null}
            </div>
            <div className="shiv_GenderOptions" data-testid="eMed_genderOptions">
              <label className='GenderLabel'>
                <input type="radio" name="gender" value="m" checked={formData.gender === 'm'} onChange={handleInputChange} data-testid="eMed_genderMale" />
                <div className="shiv_labels" style={{ marginLeft: "5px", width: "90px" }}>Male / ஆண்</div>
              </label>
              <label className='GenderLabel'>
                <input type="radio" name="gender" value="f" checked={formData.gender === 'f'} onChange={handleInputChange} data-testid="eMed_genderFemale" />
                <div className="shiv_labels" style={{ marginLeft: "5px", width: "110px" }}>Female / பெண்</div>
              </label>
              <label className='GenderLabel'>
                <input type="radio" name="gender" value="t" checked={formData.gender === 't'} onChange={handleInputChange} data-testid="eMed_genderOther" />
                <div className="shiv_labels" style={{ marginLeft: "5px" }}>Other / மற்றவர்</div>
              </label>
            </div>
            {errors.gender && <p className="error" data-testid="eMed_ageError">{errors.gender}</p>}
          </div>
        </div>

        <div className='shiv_row'>
          <div className='shiv_dobDiv' >
            <div className='shiv_rowSub'>
              <label className="shiv_labels" data-testid="eMed_nameLabel">Date of Birth / பிறந்த தேதி *</label>
              <input type="date" max={today} name="birthdate" value={formData.birthdate} onChange={handleInputChange} data-testid="eMed_dobInput" className='datePicker' />
              {errors.birthdate ? <p className="error" data-testid="eMed_dobError">{errors.birthdate}</p> : null}
            </div>
            <div className='shiv_rowSub'>
              <label className="shiv_labels" data-testid="eMed_nameLabel">Age / வயது</label>
              <TextField
                value={formData.age}
                className='textField'
                autoComplete='off'
                name="age"
                sx={{ marginTop: "0px !important" }}
                size='small'
                onChange={handleInputChange}
              />
              {errors.age && <p className="error" data-testid="eMed_ageError">{errors.age}</p>}
            </div>
          </div>
          <div className="Gender" data-testid="eMed_gender" style={{ width: "100%", marginTop: "5px" }}>
            <div className="GenderHeading">
              <label className="shiv_labels" data-testid="eMed_genderLabel">Marital Status / திருமண நிலை</label>
              {errors.maritalStatus ? <p className="error" style={{ marginTop: "2px" }} data-testid="eMed_genderError">{errors.maritalStatus}</p> : null}
            </div>
            <div className="shiv_GenderOptions" data-testid="eMed_genderOptions">
              <label className='GenderLabel'>
                <input type="radio" name="maritalStatus" value="Single" checked={formData.maritalStatus === 'Single'} onChange={handleInputChange} data-testid="eMed_marital_single" />
                <div className="shiv_labels" style={{ marginLeft: "5px" }}>Single / திருமணமாகாதவர்</div>
              </label>
              <label className='GenderLabel'>
                <input type="radio" name="maritalStatus" value="Married" checked={formData.maritalStatus === 'Married'} onChange={handleInputChange} data-testid="eMed_marital_married" />
                <div className="shiv_labels" style={{ marginLeft: "5px" }}>Married / திருமணமானவர்</div>
              </label>
            </div>
          </div>
        </div>

        <div className='shiv_row'>
          <div className='shiv_rowSub'>
            <label className="shiv_labels" data-testid="eMed_nameLabel">Father/Husband Name / தந்தை/கணவர் பெயர்</label>
            <TextField
              name="fatherName"
              value={formData.fatherName}
              inputProps={{ maxLength: 32 }}
              autoComplete='off'
              className='textField'
              sx={{ marginTop: "0px !important" }}
              size='small'
              onChange={handleInputChange}
            />
          </div>
          <div className='shiv_rowSub'>
            <label className="shiv_labels" data-testid="eMed_nameLabel">Guardian Name / பாதுகாப்பாளர் பெயர்</label>
            <TextField
              name="guardianName"
              inputProps={{ maxLength: 32 }}
              autoComplete='off'
              value={formData.guardianName}
              className='textField'
              sx={{ marginTop: "0px !important" }}
              size='small'
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className='shiv_row'>
          <div className='shiv_rowSub'>
            <label className="shiv_labels" data-testid="eMed_nameLabel">Permanent Address / நிரந்தர முகவரி</label>
            <TextField
              name="address"
              value={formData.address}
              inputProps={{ maxLength: 100 }}
              autoComplete='off'
              multiline
              className='textField'
              sx={{ marginTop: "0px !important" }}
              size='small'
              onChange={handleInputChange}
              type = "text"
            />
          </div>
          <div className='shiv_rowSub address'>
            <label className="shiv_labels" data-testid="eMed_nameLabel">Pincode /பின்கோடு</label>
            <TextField
              name="pincode"
              value={formData.pincode}
              inputProps={{ maxLength: 6 }}
              autoComplete='off'
              className='textField'
              sx={{ marginTop: "0px !important" }}
              size='small'
              onChange={handleInputChange}
              type='text'
            />
            {errors.pincode ? <p className="error" data-testid="eMed_nameError">{errors.pincode}</p> : null}
          </div>
        </div>

        <div className='shiv_row'>
          <div className='shiv_rowSub'>
            <label className="shiv_labels" data-testid="eMed_nameLabel">Mobile no / கைபேசி எண் *</label>
            <TextField
              name="phone"
              value={formData.phone}
              className='textField'
              inputProps={{ maxLength: 10 }}
              autoComplete='off'
              sx={{ marginTop: "0px !important" }}
              size='small'
              onChange={handleInputChange}
              type='text'
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div >+91</div>
                  </InputAdornment>
                ),
              }}
            />
            {errors.phone && <p className="error" data-testid="eMed_phoneError">{errors.phone}</p>}
          </div>
          <div className='shiv_rowSub email'>
            <label className="shiv_labels" data-testid="eMed_nameLabel">Email Id / மின்னஞ்சல் முகவரி</label>
            <TextField
              name="email"
              value={formData.email}
              className='textField'
              autoComplete='off'
              sx={{ marginTop: "0px !important" }}
              size='small'
              onChange={handleInputChange}
              type='email'
            />
            {errors.email && <p className="error" data-testid="eMed_emailError">{errors.email}</p>}
          </div>
        </div>

        <div className='shiv_row'>
          <div className='shiv_rowSub'>
            <label className="shiv_labels" data-testid="eMed_nameLabel">Occupation / தொழில்</label>
            <TextField
              name="occupation"
              value={formData.occupation}
              inputProps={{ maxLength: 50 }}
              className='textField'
              autoComplete='off'
              sx={{ marginTop: "0px !important" }}
              size='small'
              onChange={handleInputChange}
            />
          </div>
          <div className='shiv_rowSub email'>
            <label className="shiv_labels" data-testid="eMed_nameLabel">Aadhar Card No / ஆதார் அட்டை எண் </label>
            <TextField
              name="aadharNo"
              value={formData.aadharNo}
              inputProps={{ maxLength: 12 }}
              className='textField'
              autoComplete='off'
              sx={{ marginTop: "0px !important" }}
              size='small'
              type='text'
              onChange={handleInputChange}
            />
            {errors.aadharNo && <p className="error" data-testid="eMed_emailError">{errors.aadharNo}</p>}
          </div>
        </div>

        <div className="shiv_submitbutton">
          <input disabled={formData.isDisable} type="submit" value="Submit" data-testid="eMed_submitButton" />
        </div>
      </form>
      <div className="imagesall" data-testid="eMed_imagesall">
        <div className="emedlogo">
          <span className="alreadypat"><p data-testid="eMed_emedLogoText">Download EmedHub </p> To view your reports and bills</span>
        </div>
        <div className="appstoreandplaystore" data-testid="eMed_appstoreAndPlaystore">
          <a href="https://apps.apple.com/us/app/emedhub/id6475773185?platform=iphone*" target="_blank" rel="nofollow noopener noreferrer" data-testid="eMed_appstoreLink"> <img src={ImagePaths.AppStore} alt="Header" data-testid="eMed_appstoreImage" /></a>
          <a href="https://play.google.com/store/apps/details?id=com.emedhub.clinic&pcampaignid=web_share" target="_blank" rel="nofollow noopener noreferrer" className="playstore" data-testid="eMed_playstoreLink"> <img src={ImagePaths.PlayStore} alt="Header" data-testid="eMed_playstoreImage" /></a>
        </div>
      </div>
      {isErrorMsg ?
        <ToastMsg
          severity={'error'}
          msg={isErrorMsgText}
          msgPop={msgClose.bind(this)}
        />
        : null}
      {successMsg ?
        <ToastMsg
          severity={'success'}
          msg={successMsgText}
          msgPop={msgClose.bind(this)}
        />
        : null}
    </div>
  );
};

export default ShivaniMedicalCenter;