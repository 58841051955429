import React, { useState } from 'react';
import './RajeshClinic.css';
import ImagePaths from '../../../Utility/ImagePaths';
import { TextField } from '@mui/material';
import { post } from '../../../Utility/RestAPIService';
import { ToastMsg } from '../../CommonFunctions/CommonFuntions';

const RajeshClinic = () => {
  const [birthdate, setBirthdate] = useState('');
  const [age, setAge] = useState('');
  const [name, setName] = useState('');
  const [fatherName, setFatherName] = useState('');
  const [gender, setGender] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [pincode, setPincode] = useState('');
  const [email, setEmail] = useState('');
  const [isNotEmailValid, setIsNotEmailValid] = useState(false);
  const [refSource, setrefSource] = useState('');
  const [otherReferal, setotherReferal] = useState('');
  const [showOthersTextarea, setShowOthersTextarea] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const [isDisable, setisDisable] = useState(false);
  const [errors, setErrors] = useState({});
  const [isErrorMsg, setIsErrorMsg] = useState(false);
  const [successMsg, setsuccessMsg] = useState(false);
  const [isErrorMsgText, setIsErrorMsgText] = useState('');
  const [successMsgText, setsuccessMsgText] = useState('');

  const today = new Date().toISOString().split('T')[0];

  const handleNameChange = (event) => {
    const inputName = event.target.value;
    if (/^[a-zA-Z\s]*$/.test(inputName)) {
      if (inputName.length <= 50) {
        setName(inputName);
        setErrors((prevErrors) => ({ ...prevErrors, name: '' }));
      }
    }
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, Gender: '' }));
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setBirthdate(selectedDate);
    calculateAgeFromBirthdate(selectedDate);
    setErrors((prevErrors) => ({ ...prevErrors, birthdate: '', age: '' }));
  };

  const handleAgeChange = (event) => {
    const inputAge = event.target.value;
    if ((inputAge >= 0 && inputAge <= 150) || event.target.value == "") {
      setAge(inputAge);
      if (event.target.value != "") {
        calculateBirthdateFromAge(inputAge);
      }
    }
    setErrors((prevErrors) => ({ ...prevErrors, age: '', birthdate: '' }));
  };

  const calculateAgeFromBirthdate = (birthdate) => {
    const birthdateDate = new Date(birthdate);
    const today = new Date();
    let calculatedAge = today.getFullYear() - birthdateDate.getFullYear();
    const monthDifference = today.getMonth() - birthdateDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthdateDate.getDate())) {
      calculatedAge--;
    }
    if (calculatedAge <= 150 && calculatedAge >= 0) {
      setAge(calculatedAge);
    }
  };

  const calculateBirthdateFromAge = (inputAge) => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - inputAge);

    setBirthdate(today.toISOString().split('T')[0]);
  };

  const handlefatherNameChange = (event) => {
    const inputName = event.target.value;
    if (/^[a-zA-Z\s]*$/.test(inputName)) {
      if (inputName.length <= 50) {
        setFatherName(inputName);
        setErrors((prevErrors) => ({ ...prevErrors, fatherName: '' }));
      }
    }
  }

  const handleAddressChange = (event) => {
    const inputName = event.target.value;
    if (inputName.length <= 100) {
      setAddress(inputName);
    }
  }

  const handlePhoneChange = (event) => {
    const inputPhone = event.target.value;
    if (/^\d{0,10}$/.test(inputPhone)) {
      setPhone(inputPhone);
      setErrors((prevErrors) => ({ ...prevErrors, phone: '' }));
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    if(inputEmail?.length < 50){
      setEmail(inputEmail);
    }
    // setIsNotEmailValid(validateEmail(inputEmail));
    setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
  };

  const toggleTermsVisibility = () => {
    setShowTerms(!showTerms);
  };

  const handleAgreeTermsChange = (event) => {
    setAgreeTerms(event.target.checked);
    setisChecked(event.target.checked)
    setErrors((prevErrors) => ({ ...prevErrors, agreeTerms: '' }));
  };

  const errorMessage = (message) => {
    setIsErrorMsg(true)
    setIsErrorMsgText(message)
  }

  const successMessage = (message) => {
    setsuccessMsg(true)
    setsuccessMsgText(message)
  }

  const msgClose = () => {
    setIsErrorMsg(false)
    setIsErrorMsgText('')
    setsuccessMsg(false)
    setsuccessMsgText('')
  }

  const postPatientDetail = () => {
    let REGISTRATION_FORM_POST = 'fo/qr-appointment/registration/'
    try {
      let data = {
        "clinic_id": 10,
        "patient_name": name ? name : "",
        "gender": gender ? gender : "",
        "dob": birthdate ? birthdate : null,
        "age": age ? age : null,
        "address": address ? address : "",
        "pincode": pincode ? pincode : "",
        "mobile_number": phone ? phone : "",
        "email": email ? email : null,
        'father_name': fatherName ? fatherName : "",
        "referral_source": ""
      }
      post(REGISTRATION_FORM_POST, data)
        .then((response) => {
          if (response.data.status === 'success') {
            successMessage(response.data.message)
            setBirthdate("")
            setAge("")
            setName("")
            setGender("")
            setPhone("")
            setAddress("")
            setPincode("")
            setEmail("")
            setrefSource("")
            setShowOthersTextarea(false)
            setShowTerms(false)
            setAgreeTerms('')
            setotherReferal('')
            setisChecked(false)
            setFatherName('')
            setisDisable(false)
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            errorMessage(error.response.data.message)
            setisDisable(false)
          } else {
            errorMessage(error.message)
            setisDisable(false)
          }
        })
    } catch (error) {
      errorMessage(error.message)
      setisDisable(false)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = {};
    setisDisable(true)
    // validateEmail()

    if (!name) newErrors.name = 'Name is required';
    if (!birthdate) newErrors.birthdate = 'Birthdate is required';
    if (!age) newErrors.age = 'Age is required';
    if (!phone) newErrors.phone = 'Phone number is required';
    if (!agreeTerms) newErrors.agreeTerms = 'Please agree to the terms and conditions';
    if (!gender) newErrors.Gender = 'Please Select Gender';
    if (isNotEmailValid && email != "") newErrors.email = 'Invalid E-mail';
    if (pincode.length != 6 && pincode != "") newErrors.pincode = 'Invalid Pincode';
    if (phone.length != 10 && phone != "") newErrors.phone = 'Invalid Mobile Number';
    if (showOthersTextarea && otherReferal == "") newErrors.referal = "Enter Other Referral Field"

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      postPatientDetail()
    } else {
      setisDisable(false)
    }
  };

  return (
    <div className="formpage container OverAll" data-testid="eMed_formpage">
      <div className="header" data-testid="eMed_header">
        <img src={ImagePaths.MasonicHeaderImage} alt="Header" data-testid="eMed_headerImage" />
      </div>
      <h3 className="Title">Rajesh Clinic - Out Patient Registration Information Form</h3>
      <form onSubmit={handleSubmit} className="formPageDetails" data-testid="eMed_form">
        <div className='rowOne'>
          <div className='rowOneSub'>
            <label className="labels" data-testid="eMed_nameLabel">Patient Name / நோயாளியின் பெயர் *</label>
            <TextField
              value={name}
              onChange={handleNameChange}
              className='textField'
              size='small'
            />
            {errors.name ? <p className="error" data-testid="eMed_nameError">{errors.name}</p> : null}
          </div>
          <div className="Gender" data-testid="eMed_gender" style={{ width: "47%", marginTop: "5px" }}>
            <div className="GenderHeading">
              <label className="labels" data-testid="eMed_genderLabel">Gender / பாலினம் *</label>
              {errors.Gender ? <p className="error" style={{ marginTop: "2px" }} data-testid="eMed_genderError">{errors.Gender}</p> : null}
            </div>
            <div className="GenderOptions" data-testid="eMed_genderOptions">
              <label className='GenderLabel'>
                <input type="radio" name="yoursex" value="m" checked={gender === 'm'} onChange={handleGenderChange} data-testid="eMed_genderMale" />
                <div className="labels" style={{ marginLeft: "5px", width: "90px" }}>Male / ஆண்</div>
              </label>
              <label className='GenderLabel'>
                <input type="radio" name="yoursex" value="f" checked={gender === 'f'} onChange={handleGenderChange} data-testid="eMed_genderFemale" />
                <div className="labels" style={{ marginLeft: "5px", width: "110px" }}>Female / பெண்</div>
              </label>
              <label className='GenderLabel'>
                <input type="radio" name="yoursex" value="t" checked={gender === 't'} onChange={handleGenderChange} data-testid="eMed_genderOther" />
                <div className="labels" style={{ marginLeft: "5px" }}>Other / மற்றவர்</div>
              </label>
            </div>
          </div>
        </div>

        <div className='rowOne'>
          <div className='rowOneSub'>
            <label className="labels" data-testid="eMed_nameLabel">Date of Birth / பிறந்த தேதி *</label>
            <input type="date" max={today} value={birthdate} onChange={handleDateChange} data-testid="eMed_dobInput" className='datePicker' />
            {errors.birthdate ? <p className="error" data-testid="eMed_dobError">{errors.birthdate}</p> : null}
          </div>
          <div className='rowOneSub age'>
            <label className="labels" data-testid="eMed_nameLabel">Age / வயது *</label>
            <TextField
              value={age}
              className='textField'
              size='small'
              onChange={handleAgeChange}
            />
            {errors.age && <p className="error" data-testid="eMed_ageError">{errors.age}</p>}
          </div>
        </div>

        <div className='rowOne'>
          <div className='rowOneSub'>
            <label className="labels" data-testid="eMed_nameLabel">Father/Guardian Name: தந்தை பெயர்</label>
            <TextField
              value={fatherName}
              className='textField'
              size='small'
              onChange={handlefatherNameChange}
            />
          </div>
          <div className='rowOneSub address'>
            <label className="labels" data-testid="eMed_nameLabel">Address / முகவரி</label>
            <div className="Input-Container">
              <textarea value={address} onChange={handleAddressChange} data-testid="eMed_addressInput" className='addressBar'></textarea>
            </div>
          </div>
        </div>

        <div className='rowOne'>
          <div className='rowOneSub'>
            <label className="labels" data-testid="eMed_nameLabel">Mobile no / கைபேசி எண் *</label>
            <div className="countrycodediv">
              <span className="countryCode">+91</span>
              <input type="text" value={phone} className='phoneInput' onChange={handlePhoneChange} data-testid="eMed_phoneInput" />
            </div>
            {errors.phone && <p className="error" data-testid="eMed_phoneError">{errors.phone}</p>}
          </div>
          <div className='rowOneSub email'>
            <label className="labels" data-testid="eMed_nameLabel">Email Id / மின்னஞ்சல் முகவரி</label>
            <TextField
              value={email}
              className='textField'
              size='small'
              onChange={handleEmailChange}
              type='email'
            />
            {isNotEmailValid && email && <p className="error" data-testid="eMed_emailError">Please enter a valid email address.</p>}
          </div>
        </div>

        <div className="termsandcondition" data-testid="eMed_termsandcondition">
          <span className="tandcall">
            <input type="checkbox" id="termsandcondition" checked={isChecked} name="termsandcondition" value="howdidyou" onChange={handleAgreeTermsChange} data-testid="eMed_termsCheckbox" />
            <p className="termsandconditionshow" onClick={toggleTermsVisibility} data-testid="eMed_termsToggle"> I agree to the terms and conditions / நான் விதிமுறைகளை ஏற்றுக்கொள்கிறேன் {showTerms ? '▲' : '▼'}</p>
          </span>
          {showTerms && (
            <p className="termspara label" data-testid="eMed_termsText">
              <span>I have been appraised of the rules & regulations of the Hospital and also the charges for treatment. I give my consent, to be examined / the patient to be examined by the Doctors. Nurses of the Hospital and to undergo essential test and procedures for diagnosis and take treatment by the Doctor.                         </span>  <br /><br /><br />
              <span>இம்மருத்துவமனையில் நோயாளிகள் சிகிச்சை பெறுவதற்கான சட்ட திட்டங்களையும் கட்டண விகிதங்களையும் தெளிவுற அறிந்தேன். மேலும் இம்மருத்துவமனையின் மருத்துவர்கள். மருத்துவ ஆலோசகர்கள் மற்றும் செவிலியர்கள், எனது / நோயாளியின் சிகிச்சையின் நிமித்தம் என்னை / நோயாளியை உடற்பரிசோதனை மேற்கொள்ளவும் நோயை கண்டறிய அத்தியாவசிய பரிசோதனைகள் மற்றும் சிகிச்சைகள் எனக்கு / நோயாளிக்கு அளிக்கவும் மனப்பூர்வமான எனது சம்மதத்தை இதன்மூலம் தெரிவித்துக் கொள்கிறேன்.</span>
            </p>
          )}
          {errors.agreeTerms && <p className="erroragree" data-testid="eMed_termsError">{errors.agreeTerms}</p>}
        </div>

        <div className="submitbutton">
          <input disabled={isDisable} type="submit" value="Submit" data-testid="eMed_submitButton" />
        </div>
      </form>
      <div className="imagesall" data-testid="eMed_imagesall">
        <div className="emedlogo">
          <span className="alreadypat"><p data-testid="eMed_emedLogoText">Download EmedHub </p> To view your reports and bills</span>
        </div>
        <div className="appstoreandplaystore" data-testid="eMed_appstoreAndPlaystore">
          <a href="https://apps.apple.com/us/app/emedhub/id6475773185?platform=iphone*" target="_blank" rel="nofollow" data-testid="eMed_appstoreLink"> <img src={ImagePaths.AppStore} alt="Header" data-testid="eMed_appstoreImage" /></a>
          <a href="https://play.google.com/store/apps/details?id=com.emedhub.clinic&pcampaignid=web_share" target="_blank" rel="nofollow" className="playstore" data-testid="eMed_playstoreLink"> <img src={ImagePaths.PlayStore} alt="Header" data-testid="eMed_playstoreImage" /></a>
        </div>
      </div>
      {isErrorMsg ?
        <ToastMsg
          severity={'error'}
          msg={isErrorMsgText}
          msgPop={msgClose.bind(this)}
        />
        : null}
      {successMsg ?
        <ToastMsg
          severity={'success'}
          msg={successMsgText}
          msgPop={msgClose.bind(this)}
        />
        : null}
    </div>
  );
};

export default RajeshClinic;